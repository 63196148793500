export const tabs = [
  {
    label: '公告列表',
    value: 'anouncementList'
  },
  {
    label: '草稿箱',
    value: 'drafts'
  },
  {
    label: '回收站',
    value: 'recycled'
  }
]